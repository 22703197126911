import React from 'react';

import { Link } from 'gatsby';

// CSS
import * as Styles from './css/TwoColTextContent.module.css';

// Components
import ArrowIcon from '../assets/images/icons/arrow-large.svg';

// Tools
import { isExternalUrl } from '../utils';

function TwoColTextContent(props) {
	const {
		items = [{}, {}],
	} = props;

	return (
		<section className={Styles.root}>
			<div className={Styles.cols}>
				{items.map((item, i) => {
					const {
						title = 'Title',
						copy = 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Reprehenderit dolores fugit optio qui similique totam nihil placeat illo.',
						link = '/press-room',
					} = item;

					const isExternalLink = isExternalUrl(link);

					return isExternalLink
						? (
							<a
								key={i} // eslint-disable-line
								href={link}
								target={`_blank`}
								rel={`noreferrer`}
								className={Styles.col}
							>
								<div className={Styles.colBody}>
									<h3>{title}</h3>
									<p>{copy}</p>
									<ArrowIcon className={Styles.icon} />
								</div>
							</a>
						)
						: (
							<Link
								key={i} // eslint-disable-line
								to={link}
								className={Styles.col}
							>
								<div className={Styles.colBody}>
									<h3>{title}</h3>
									<p>{copy}</p>
									<ArrowIcon className={Styles.icon} />
								</div>
							</Link>
						);
				})}
			</div>
		</section>
	);
}

export default TwoColTextContent;
