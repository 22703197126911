// extracted by mini-css-extract-plugin
export var body = "Index-module--body--cDU3t";
export var container = "Index-module--container--Snou7";
export var image = "Index-module--image--Iz21U";
export var intro = "Index-module--intro--lgOYx";
export var promoblock = "Index-module--promoblock--ZQSzP";
export var promocontainer = "Index-module--promocontainer--b8FmT";
export var promoimage = "Index-module--promoimage--loxUH";
export var promolink = "Index-module--promolink--URiKw";
export var promotext = "Index-module--promotext--0lpH1";
export var promotextitalics = "Index-module--promotextitalics--fxBPL";
export var spacer = "Index-module--spacer--IZ9jM";
export var title = "Index-module--title--ndhrT";